import { useAnchorTag } from "@dolthub/react-hooks";
import SignedOutLayout from "@layouts/SignedOutLayout";
import Compare from "./Compare";
import Features from "./Features";
import Steps from "./Steps";
import Top from "./Top";
import css from "./index.module.css";

export default function HomePage() {
  useAnchorTag();
  return (
    <SignedOutLayout hideClouds>
      <div className={css.container} data-cy="homepage-container">
        <div className={css.bg}>
          <Top />
          <Steps />
        </div>
        <Features />
        <Compare />
      </div>
    </SignedOutLayout>
  );
}
