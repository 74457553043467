export const doltFeatures = [
  "Local",
  "Use the Dolt command line interface",
  "Work with versioned datasets locally",
];
export const hostedFeatures = [
  "Online",
  "Connect with a MySQL client",
  "Build a Dolt-powered application",
];
export const dolthubFeatures = [
  "Offline",
  "Connect with clone, push, pull, or fetch",
  "Collaborate asynchronously",
];

export const features = [doltFeatures, hostedFeatures, dolthubFeatures];
