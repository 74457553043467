import DoltLink from "@components/links/DoltLink";
import Link from "@components/links/Link";
import { Button, ExternalLink } from "@dolthub/react-components";
import { dolthubLink, doltlabLink } from "@lib/constants";
import { createDeployment } from "@lib/urls";
import { BsArrowUpRight } from "@react-icons/all-files/bs/BsArrowUpRight";
import cx from "classnames";
import css from "./index.module.css";

export const linkImages = [
  <DoltLink key="dolt-link-image">
    <img
      alt="Dolt"
      src="/images/dolt-logo.png"
      className={cx(css.tableImage, css.doltImg)}
    />
  </DoltLink>,
  <Link href="/signin" key="hosted-link-image">
    <img
      alt="Hosted Dolt"
      src="/images/Hosted-Dolt-Logo.svg"
      className={cx(css.tableImage, css.hostedImg)}
    />
  </Link>,
  <div className={css.dolthublab} key="dolthublab-link-image">
    <ExternalLink href={dolthubLink}>
      <img
        alt="DoltHub"
        src="/images/Dolthub-Logo.svg"
        className={cx(css.tableImage, css.toRight)}
        height={20}
        width={100}
      />
    </ExternalLink>
    <div className={css.icon}>/</div>
    <ExternalLink href={doltlabLink}>
      <img
        alt="DoltLab"
        src="/images/Doltlab-Logo.svg"
        className={css.toLeft}
        height={20}
        width={100}
      />
    </ExternalLink>
  </div>,
];

export const bottomLinks = [
  <DoltLink className={css.link} key="dolt-link" data-cy="compare-link-started">
    Get started <BsArrowUpRight className={css.arrow} />
  </DoltLink>,
  <Link key="create-database-link" {...createDeployment({})}>
    <Button.Link className={css.link} data-cy="compare-link-database">
      Launch a database <BsArrowUpRight className={css.arrow} />
    </Button.Link>
  </Link>,
  <ExternalLink
    href={dolthubLink}
    className={css.link}
    data-cy="compare-link-DoltHub"
    key="dolthub-link"
  >
    Visit DoltHub
    <BsArrowUpRight className={css.arrow} />
  </ExternalLink>,
];
