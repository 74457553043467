import { ExternalLink } from "@dolthub/react-components";
import { awsRDSLink, mariaDBLink, teamLink } from "@lib/constants";
import { AiFillTool } from "@react-icons/all-files/ai/AiFillTool";
import { BsArrowUpRight } from "@react-icons/all-files/bs/BsArrowUpRight";
import { BsFillPersonCheckFill } from "@react-icons/all-files/bs/BsFillPersonCheckFill";
import { IoDocumentTextSharp } from "@react-icons/all-files/io5/IoDocumentTextSharp";
import { IoStatsChartSharp } from "@react-icons/all-files/io5/IoStatsChartSharp";
import cx from "classnames";
import css from "./index.module.css";

export default function Features() {
  return (
    <div className={css.container}>
      <div className={css.content} data-cy="features-container">
        <div className={css.left}>
          <h2 className={css.leftHeader}>
            Let the Dolt experts run your database for you
          </h2>
          <p className={css.leftText}>
            Hosted Dolt is our answer to{" "}
            <ExternalLink
              className={css.linkBlue}
              href={awsRDSLink}
              data-cy="features-link-awsRDS"
            >
              AWS RDS
            </ExternalLink>{" "}
            or{" "}
            <ExternalLink
              className={css.linkBlue}
              href={mariaDBLink}
              data-cy="features-link-mariaDB"
            >
              MariaDB SkySQL
            </ExternalLink>
            . Our team of veteran cloud service engineers will keep your
            database operating smoothly.
          </p>

          <ExternalLink
            href={teamLink}
            className={cx(css.link, css.linkBlue)}
            data-cy="features-link-team"
          >
            Learn about the team <BsArrowUpRight className={css.arrow} />
          </ExternalLink>
        </div>

        <div className={css.right}>
          <div className={css.col}>
            <BsFillPersonCheckFill className={css.iconColor} />
            <h3 className={css.colHeader}>Fully Managed</h3>
            <p className={css.colText}>
              Dolt engineers on call to solve any issues
            </p>

            <IoDocumentTextSharp className={css.iconColor} />
            <h3 className={css.colHeader}>Logs</h3>
            <p className={css.colText}>Search detailed query logs</p>
          </div>
          <div className={css.col}>
            <IoStatsChartSharp className={css.iconColor} />
            <h3 className={css.colHeader}> Monitoring </h3>
            <p className={css.colText}>
              See performance and usage in real time
            </p>

            <AiFillTool className={css.iconColor} />
            <h3 className={css.colHeader}> And more!</h3>
            <p className={css.colText}>
              Backups, custom config, upgrades, replication
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
