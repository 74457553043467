import DesktopTable from "./DesktopTable";
import css from "./index.module.css";
import MobileTable from "./MobileTable";

export default function Compare() {
  return (
    <div className={css.bgGrey}>
      <div
        className={css.container}
        data-cy="compare-container"
        id="which-dolt-is-right-for-you"
      >
        <h2 className={css.header}>Which Dolt is right for you?</h2>
        <DesktopTable />
        <MobileTable />
      </div>
    </div>
  );
}
