import { ListSteps } from "@components/ListItemWithConnectedBullet";
import { ExternalLink } from "@dolthub/react-components";
import { hostedVideoLink } from "@lib/constants";
import { ImPlay2 } from "@react-icons/all-files/im/ImPlay2";
import cx from "classnames";
import css from "./index.module.css";

export default function Steps() {
  const steps = [
    "Sign in",
    "Create a Deployment",
    "Connect with any MySQL Client",
    "Build",
  ];
  return (
    <div className={css.container} data-cy="steps-container">
      <div className={css.flexRow}>
        <h2 className={css.stepHeader}>Get started in minutes</h2>

        <div className={css.rightPadding}>
          <ExternalLink
            href={hostedVideoLink}
            className={cx(css.gradient, css.linkToVideo)}
          >
            <ImPlay2 className={css.icon} /> Watch the Video
          </ExternalLink>
        </div>
      </div>

      <div className={css.line} />
      <h2 className={cx(css.header, css.showMobile)}>Get started in minutes</h2>
      <ul className={css.steps}>
        {steps.map((step, i) => (
          <ListSteps key={step} num={i + 1} text={step} />
        ))}
      </ul>
      <ExternalLink
        href={hostedVideoLink}
        className={cx(css.gradient, css.linkToVideo, css.showMobile)}
      >
        <ImPlay2 className={css.icon} /> Watch the Video
      </ExternalLink>
      <img className={css.imgWidth} alt="curve" src="/images/curve.svg" />
    </div>
  );
}
