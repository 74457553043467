import cx from "classnames";
import { features } from "../constants";
import { bottomLinks, linkImages } from "../links";
import css from "./index.module.css";

export default function MobileTable() {
  return (
    <div className={css.tableContainer}>
      {features.map((feature, i) => (
        <table className={css.table} key={`table-${feature[0]}`}>
          <thead>
            <tr>
              <th className={cx({ [css.roundTop]: i === 0 })}>
                {linkImages[i]}
              </th>
            </tr>
          </thead>
          <tbody>
            {feature.map((f, ind) => (
              <tr key={f}>
                <td>
                  <p
                    className={cx(css.regularText, {
                      [css.narrowText]: i === 1 && ind === 1,
                    })}
                  >
                    {f}
                  </p>
                </td>
              </tr>
            ))}
            <tr>
              <td
                className={cx({
                  [css.roundBottom]: i === bottomLinks.length - 1,
                })}
              >
                <p>{bottomLinks[i]}</p>
              </td>
            </tr>
          </tbody>
        </table>
      ))}
    </div>
  );
}
