import { ListItems } from "@components/ListItemWithConnectedBullet";
import Link from "@components/links/Link";
import { Button } from "@dolthub/react-components";
import { createDeployment } from "@lib/urls";
import { BsArrowUpRight } from "@react-icons/all-files/bs/BsArrowUpRight";
import cx from "classnames";
import css from "./index.module.css";

export default function Top() {
  const list = [
    "Cloud-hosted Dolt database",
    "Built-in backups and monitoring",
    "Customizable instance size and storage",
  ];

  return (
    <div className={css.container} data-cy="top-container">
      <div className={css.flexTop}>
        <h3 className={css.subHeader}> DOLT IN THE CLOUD</h3>
        <h1 className={css.header}>The easiest way to build with Dolt.</h1>
        <div className={css.linkGroup}>
          <Link {...createDeployment({})}>
            <Button
              data-cy="top-database-button"
              className={cx(css.linkButton, css.getStarted, css.gradient)}
            >
              Launch a database
            </Button>
          </Link>
          <Link href="/pricing">
            <Button.Link
              data-cy="top-pricing-button"
              className={cx(css.linkButton, css.viewPricing)}
            >
              View pricing <BsArrowUpRight className={css.arrow} />
            </Button.Link>
          </Link>
        </div>
      </div>
      <div className={css.flexCol}>
        <ul className={css.list}>
          {list.map(text => (
            <ListItems key={text} text={text} />
          ))}
        </ul>
      </div>
    </div>
  );
}
