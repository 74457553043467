import cx from "classnames";
import { useState } from "react";
import css from "./index.module.css";
import { doltFeatures, dolthubFeatures, hostedFeatures } from "../constants";
import { bottomLinks, linkImages } from "../links";

export default function DesktopTable() {
  const [showGradient, setGradient] = useState(false);

  const onScroll = () => {
    const tableContainerView = document.getElementById("tableContainer");
    if (
      tableContainerView &&
      (tableContainerView.scrollWidth - tableContainerView.clientWidth) * 0.9 <=
        tableContainerView.scrollLeft
    ) {
      setGradient(false);
    } else {
      setGradient(true);
    }
  };

  return (
    <div className={css.tableContainer} id="tableContainer" onScroll={onScroll}>
      <table className={css.table}>
        <thead>
          <tr>
            {linkImages.map((linkImage, i) => (
              <th
                key={linkImage.key}
                className={cx({
                  [css.roundTopLeft]: i === 0,
                  [css.roundTopRight]: i === linkImages.length - 1,
                })}
              >
                {linkImage}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 3 }).map((_, i) => (
            <tr key={`row${doltFeatures[i]}`}>
              <td>{doltFeatures[i]}</td>
              <td className={cx({ [css.narrowText]: i === 1 })}>
                {hostedFeatures[i]}
              </td>
              <td>{dolthubFeatures[i]}</td>
            </tr>
          ))}
          <tr>
            {bottomLinks.map((bottomLink, i) => (
              <td
                key={bottomLink.key}
                className={cx({
                  [css.roundBottomLeft]: i === 0,
                  [css.roundBottomRight]: i === bottomLinks.length - 1,
                })}
              >
                <p>{bottomLink}</p>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <div className={cx({ [css.gradient]: showGradient })} />
    </div>
  );
}
